import React from "react"

import { Link } from "gatsby"
import {
  MdDirectionsBike,
  MdOutlineSkateboarding,
  MdOutlineKayaking,
} from "react-icons/md"
import { FaHiking, FaSkiingNordic } from "react-icons/fa"
import { GiMountainClimbing } from "react-icons/gi"
import { Layout, Seo, PageSection, randomImg, classes } from "../layout"
import {
  AvailableAsWebApp,
  FindOutMoreButton,
  IPhone,
  IPhoneCarousel,
  img,
} from "../components"

export default function IndexPage(props) {
  const {
    sectionText,
    statement,
    heading,
    paragraph,
    iphone,
    imageRight,
    imageLeft,
  } = classes
  const icon = "w-6 h-6 sm:w-8 sm:h-8 mr-5 sm:mr-8"
  const under_heading = "font-light -mt-4"

  return (
    <Layout header="light" language="no" path={props.path}>
      <Seo title="Hjem" />

      <div
        className="relative w-full sm:min-h-screen tg-front-image"
        style={{
          backgroundImage: `url("${randomImg()}")`,
        }}
      >
        <div className="w-full max-w-5xl mx-auto flex justify-around pt-32 pb-8 sm:pb-16 md:px-4 lg:px-8">
          <div className="text-white">
            <div className="flex px-4">
              <MdDirectionsBike className={icon} />
              <FaHiking className={icon} />
              <FaSkiingNordic className={icon} />
              <MdOutlineSkateboarding className={icon} />
              <MdOutlineKayaking className={icon} />
              <GiMountainClimbing className={icon} />
            </div>
            <h1 className="px-4 text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-left">
              Digitalisering av reiselivet
            </h1>
            <div className="text-lg p-4 sm:text-2xl sm:leading-10 mb-12 max-w-xl">
              Trailguide utvikler programvare og teknologi for å digitalisere
              reiselivssektoren og enkelt gjøre utendørsaktiviteter tilgjengelig
              for gjester og besøkende
            </div>

            <FindOutMoreButton
              className="mx-4 shadow-md sm:border-2 border-green-100"
              to="#app"
            >
              Les mer
            </FindOutMoreButton>
          </div>

          <div className="hidden md:block">
            <Carousel color="white" />
          </div>
        </div>
      </div>

      <div className="md:hidden flex justify-center py-12">
        <Carousel color="black" />
      </div>

      <PageSection id="who">
        <div className={sectionText}>
          <div className="max-w-3xl mx-auto">
            <p className={paragraph}>
              I Trailguide er vi overbevist at teknologi kan forbedre og berike
              naturopplevelsen. Derfor utvikler vi innovative programvare- og
              teknologiløsninger spesielt designet for reiselivsbransjen.
            </p>
            <p className={paragraph}>
              Målet vårt er å hjelpe deg å digitalisere utendørsaktivitetene
              dine og gjøre dem tilgjengelige for dine gjester og besøkende på
              en enkel måte.
            </p>
            <p className={paragraph}>
              La oss hjelpe deg med å ta utendørsopplevelsene dine til neste
              nivå med vår banebrytende teknologi.
            </p>
          </div>
        </div>
      </PageSection>

      <PageSection id="app">
        <h1 className={heading}>Umiddelbar tilgang til turinformasjon</h1>
        <h4 className={under_heading}>
          Appen åpnes på 1 sekund ved å skanne en QR-kode
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              Trailguide er en app som hjelper deg å finne fantastiske
              utendørsaktiviteter.
            </p>
            <p className={paragraph}>
              Innholdet på Trailguide er lagt inn av entusiaster med
              lokalkunnskap og du vil alltid finne innhold av bra kvalitet
              uansett hvor du befinne deg eller bestemmer deg for å reise.
            </p>
            <p className={paragraph}>
              Trailguide har digitale kart, turbeskrivelser, anmeldelser,
              førerapporter, og selvfølgelig live GPS-navigasjon.
            </p>
            <p className={paragraph}>
              Som en turistdestinasjon eller resort kan du tilby dine gjester og
              besøkende aksess til eksisterende eller eget innhold via appen og
              ha din egen skreddersydde digitale turistguide fiks ferdig. Ingen
              installasjon eller brukerregistrering er nødvendig.
            </p>
            <div className="flex flex-wrap items-start my-12">
              <Link href="https://trailguide.net" target="trailguide">
                <AvailableAsWebApp label="trailguide.net" />
              </Link>
              <FindOutMoreButton
                className="mt-4 md:mt-0 md:ml-4 whitespace-no-wrap md:h-14"
                to="/products/app"
              >
                Find ut mer
              </FindOutMoreButton>
            </div>
          </div>
          <IPhone
            alt="En app for å finne og dele utendørsaktiviteter"
            src={img("/website/trailguide-iphone-world-4.jpeg")}
            className={`hidden sm:block ${iphone}`}
          />
        </div>
      </PageSection>

      <PageSection id="sandbox">
        <h1 className={heading}>Din egen turapp</h1>
        <h4 className={under_heading}>Total kontroll over eget innhold</h4>
        <div className={sectionText}>
          <div>
            <img
              alt="Trysil bike park app icon"
              className="rounded shadow w-24 float-left mr-8 mb-4"
              src={img("website/trailguide-sandbox-icon-trysil-bike-park.jpg")}
            />
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              Ha full kontroll over turinnholdet med din egen skreddersydde
              turapp bygget på vår solide plattform.
            </p>
            <p className={paragraph}>
              Innholdet du presenter til dine gjester og besøkende er begrenset
              til de turene og aktivitetene som er relevante for din
              destinasjon.
            </p>
            <p className={paragraph}>
              Appen er en moderne web app som gjør det enkelt å få riktig og
              relevan informasjon i hendene på dine gjester. Det eneste de
              trenger å gjøre er å skanne en QR-kode eller klikke en link.
            </p>
            <p className={paragraph}>
              Selv om det ikke er nødvendig å installere appen, kan gjestene
              gjøre dette om de vil ved å legge den på hjemskjermen. Appen vil
              da ha destinasjonens ikon og eget navn.
            </p>
            <FindOutMoreButton
              className="mr-4 whitespace-no-wrap"
              to="/products/whitelabel"
            >
              Finn ut mer
            </FindOutMoreButton>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("/website/trailguide-iphone-sandbox.jpeg")}
            className={`hidden sm:block ${iphone}`}
          />
        </div>
      </PageSection>

      <div id="statement" className={statement}>
        <div className="italic">
          <span className="text-2xl text-gray-700 pr-2">"</span>
          Vi har en målsetting om at det skal være enkelt å være gjest i Trysil.
          Trailguide har funksjonalitet som gjør det enklere for alle å finne
          frem til sykkel- og turstier i Trysil. Det er også bra at man ikke
          trenger å laste ned en app på forhånd og registrere en profil.
          Løsningen integreres på trysil.com, og ute på stiene våre kan du
          enkelt skanne en QR-kode for å finne de aktuelle stiene på vår
          skreddersydde turapp.
          <span className="absolute text-2xl text-gray-700 pl-2">"</span>
        </div>
        <p className="ml-12 mt-4">- Olve Norderhaug, Destinasjon Trysil</p>
      </div>

      <PageSection id="presence">
        <h1 className={heading}>Sørg for at du er synlig</h1>
        <h4 className={under_heading}>
          Legg til din turistdestinasjon i Trailguide
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              La potensielle gjester og besøkende finne deg ved å legge din
              turistdestinasjon, resort, hotell, campingplass, butikk eller kafé
              inn i Trailguide-appen.
            </p>
            <p className={paragraph}>
              Forsike deg om at Trailguide-brukerne finner deg mens de bruker
              appen for ferieplanlegging eller mens de er i din regionen og
              nyter naturen rundt deg.
            </p>
            <p className={paragraph}>
              Besøkende trenger kanskje et sted å sove, mat og drikke, kjøpe
              litt ekstra utstyr eller kanskje fikse en ødelagt sykkel.
            </p>
            <p className={paragraph}>
              Ved å være synlig på Trailguide øker du sjansen for flere gjester
              og besøkende vil tilbringe mer tid i regionen.
            </p>
            <FindOutMoreButton className="mt-8" to="/no/products/presence">
              Finn ut mer
            </FindOutMoreButton>
          </div>
          <div className="hidden sm:block">
            <IPhone
              alt="Sikre din tilstedeværelse på Trailguide"
              src={img("/website/trailguide-iphone-presence2.png")}
              className={iphone}
            />
          </div>
        </div>
      </PageSection>

      <PageSection id="integration">
        <h1 className={heading}>Vis frem potensialet ditt tidlig</h1>
        <h4 className={under_heading}>
          Integrer innhold fra Trailguide på egne websider
        </h4>
        <div className="my-6 md:my-12 flex items-start">
          <div>
            <p className={paragraph}>
              La dine potensielle gjester bli inspirert av de fantastiske
              utendørsaktiviteter i din region når de besøker dine websider.
              Integrer innholdet direkte fra Trailguide sin database.
            </p>
            <p className={paragraph}>
              Unngå oppdatering av innholdet ditt på flere steder. Ha
              sentralisert kontroll av alt innhold via Trailguide sin
              innholdsportal.
            </p>
            <p className={paragraph}>
              La hele teamet ditt dele tilgang slik at du kan være sikker på at
              innholdet oppdateres og holdes aktuelt på alle dine digitale
              flater.
            </p>
            <p className={paragraph}>
              Integrasjonsmodulen er enkel å legge til, rask og kan vise innhold
              på ulike vis slik at det passer inn på dine websider.
            </p>
            <p className={paragraph}>
              <FindOutMoreButton className="mt-8" to="/products/plugin">
                Les mer om integrasjonsmodulen her
              </FindOutMoreButton>
            </p>
          </div>
          <div className="hidden sm:block ml-8 mt-8">
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 mb-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
 license="your_license_key"
 content="8064,6985,6816,8173"
 mode="carousel">
</trailguide-plugin>
`.trim()}
            </pre>
            <pre className="bg-blue-900 text-blue-100 px-6 py-4 text-sm rounded shadow-md">
              {/* prettier-ignore */}
              {`<trailguide-plugin
   license="your_license_key"
   content="7783,8064"
   lang="no"
   mode="full">
</trailguide-plugin>
  `.trim()}
            </pre>
          </div>
        </div>
      </PageSection>

      <PageSection id="landing-page">
        <h1 className={heading}>Det skal være lett å finne de beste turene</h1>
        <h4 className={under_heading}>
          Ha din egen landingsside på Trailguide
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              Sørg for at dine gjester og besøkende har enkel tilgang til en
              oversikt over dine beste turer i ditt nærområde.
            </p>
            <p className={paragraph}>
              Ved å ha din egen landingsside i Trailguide-appen samler du alle
              dine lokale turer og aktiviteter på én side slik at dine gjester
              enkelt kan få oversikt og velge det som passer dem best.
            </p>
            <p className={paragraph}>
              Landingssiden er tilgjengelig for brukeren fra kartet i
              Trailguide, eller gjester kan åpne den enkelt ved å skanne en
              QR-kode som du for eksempel har satt ut i resepsjonen din.
            </p>
            <p className={paragraph}>
              Å ha en landingsside i Trailguide er som å ha din egen
              skreddersydde digitale turguide tilgjengelig.
            </p>
            <p className={paragraph}>
              <FindOutMoreButton className="mt-8" to="/products/landingpage">
                Finn ut mer
              </FindOutMoreButton>
            </p>
          </div>
          <IPhone
            alt="Landingsside på Trailguide"
            src={img("/website/trailguide-iphone-landingpage-rides.jpeg")}
            className={iphone}
          />
        </div>
      </PageSection>

      <PageSection id="qr-codes">
        <h1 className={heading}>
          Gjester bør alltid ha tilgang til informasjon
        </h1>
        <h4 className={under_heading}>
          Koble dine fysiske omgivelser til Trailguide
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              Heng et fantastisk, nesten 3D-aktig, kart på veggen din i
              resepsjonen eller butikken din for å inspirere gjester til å komme
              seg ut å oppleve landskapet rundt dem.
            </p>
            <p className={paragraph}>
              Hvert kart er skreddersydd for ditt område og skrives ut med
              veldig høy oppløsning i opptil 2.5 meters bredde.
            </p>
            <p className={paragraph}>
              Kartene har en QR-kode dine gjester og besøkende kan skanne og få
              umiddelbar tilgang til Trailguide med din turinformasjon, digitale
              kart og GPS navigasjon.
            </p>
          </div>
          <img
            className={imageRight}
            alt="Skann QR-koden for å åpne din egen turguide"
            src={img("/website/trailguide-scan-map.jpg")}
          />
        </div>

        <div className={sectionText}>
          <img
            className={imageLeft}
            alt="Gjør din digitale turguide tilgjengelig via QR-koder der de møter deg"
            src={img("/website/trailguide-qr-display.jpg")}
          />
          <div>
            <p className={paragraph}>
              Vi tror virkelig på viktigheten av å ta vare på dine gjester og gi
              dem den beste opplevelsen de kan få under oppholdet.
            </p>
            <p className={paragraph}>
              Når dine gjester oppdager deg, når de kommer, når de går utendørs
              for å oppleve området, hele tiden burde de ha enkel tilgang til
              relevant digital informasjon
            </p>
            <p className={paragraph}>
              Ha QR-koden din tilgjengelig der hvor gjestene først kommer, slik
              som feks på resepsjonsdisken. De vil da umiddelbart ha tilgang på
              alle relevante turopplysninger.
            </p>
          </div>
        </div>
      </PageSection>

      <div id="statement2" className={statement}>
        <div className="italic">
          <span className="text-2xl text-gray-700 pr-2">"</span>
          Trailguide er en fantastisk platform for turistinformasjonen vår og
          hjelper de som jobber der til å foreslå relevante turopplevelser for
          våre besøkende. Vi slipper å trykke altfor mange brosjyrer, som igjen
          sparer miljøet, og siden alt ligger digitalt kan vi alltid sørge for
          at informasjonen på nettet er oppdatert. Dette gjør vår hverdag som
          turistinformasjon lettere
          <span className="absolute text-2xl text-gray-700 pl-2">"</span>
        </div>
        <p className="ml-12 mt-4">- Mari Clementine Østgaard, Visit Ål</p>
      </div>

      <PageSection id="no-installation">
        <h1 className={heading}>Dine gjester skal ikke måtte jobbe</h1>
        <h4 className={under_heading}>
          Umiddelbar tilgang uten installasjon eller brukerregistrering
        </h4>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              Vi tror sterkt på det å ta vare på dine gjester og har utviklet
              våre produkter og teknologi etter denne tankegangen.
            </p>
            <p className={paragraph}>
              Dine gjester skal ikke måtte hoppe gjennom hindringer for å få
              aksess til nødvendig turinformasjon. Og fremfor alt bør de
              absolutt ikke måtte betale for slik tilgang.
            </p>
            <p className={paragraph}>
              Mennesker liker sjelden hindringer på sin veg. De vil fort gi opp
              om de må laste ned en app, installere den, for så å måtte
              registere seg for å få aksess til informasjonen de er ute etter.
              Hvis de da attpåtil ender opp med å måtte betale så er ikke det en
              spesielt god gjesteopplevelse.
            </p>
          </div>
          <img
            className={imageRight}
            alt="Umiddelbar aksess, ingen installasjon, registrering eller kostnad"
            src={img("/website/trailguide-qr-display2.jpg")}
          />
        </div>
        <p className={paragraph}>
          Dine gjester bør bare måtte{" "}
          <Link to="#qr-codes">skanne en QR-kode</Link> og få umiddelbar tilgang
          til turinformasjon, digitale kart og GPS-navigasjon. Dette er akkurat
          hva Trailguide er laget for, og dine gjester trenger ikke betale noe.
        </p>
      </PageSection>
    </Layout>
  )
}

function Carousel(props = {}) {
  const { color, ...rest } = props
  return (
    <IPhoneCarousel
      color={color || "black"}
      images={[
        {
          label:
            "Trailguide har fantastiske utendørsaktiviteter over hele verden",
          src: img("/website/trailguide-iphone-world-4.jpeg"),
        },
        {
          label: "Sikre din turistdestinasjons tilstedeværelse på Trailguide",
          src: img("/website/trailguide-iphone-presence.jpeg"),
        },
        {
          label: "Ha din egen digitale turistguide på Trailguide",
          src: img("/website/trailguide-iphone-landingpage.jpeg"),
        },
        {
          label:
            "Enkel og oversiktlig tilgang på turer for gjester og besøkende",
          src: img("/website/trailguide-iphone-landingpage2.jpeg"),
        },
        {
          label: "Detaljert turinformasjon, digitale kart og merkede ruter",
          src: img("/website/trailguide-iphone-map-detail.jpeg"),
        },
        {
          label: "Innebygget GPS-navigasjon for dine gjester og besøkende",
          src: img("/website/trailguide-iphone-navigation.png"),
        },
      ]}
      {...rest}
    />
  )
}
